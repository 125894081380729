var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"speech-art-edit"},[_c('van-form',{attrs:{"validate-first":""},on:{"failed":_vm.onFailed,"submit":_vm.onSubmit}},[_c('div',{staticClass:"field-wrapper"},[_c('van-field',{staticClass:"border-bottom",attrs:{"name":"validator","maxlength":"100","placeholder":"请输入话术标题","rules":[
          {
            required: true,
            message: '必填'
          }
        ]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('van-field',{attrs:{"rows":"2","autosize":"","type":"textarea","maxlength":"500","placeholder":"请输入话术内容","show-word-limit":"","rules":[
          {
            required: true,
            message: '必填'
          }
        ]},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',{staticClass:"btns"},[_c('van-button',{attrs:{"color":"#000","size":"large","native-type":"submit","disabled":_vm.btnDisabled}},[_vm._v("保存")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }