<template>
  <div class="speech-art-edit">
    <van-form validate-first @failed="onFailed" @submit="onSubmit">
      <div class="field-wrapper">
        <van-field
          v-model="form.title"
          name="validator"
          maxlength="100"
          placeholder="请输入话术标题"
          class="border-bottom"
          :rules="[
            {
              required: true,
              message: '必填'
            }
          ]"
        />
        <van-field
          v-model="form.content"
          rows="2"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请输入话术内容"
          show-word-limit
          :rules="[
            {
              required: true,
              message: '必填'
            }
          ]"
        />
      </div>
      <div class="btns">
        <van-button
          color="#000"
          size="large"
          native-type="submit"
          :disabled="btnDisabled"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { speechArtAdd, speechArtUpdate } from '@/api/modules/vSearch'
import { Toast } from 'vant'
export default {
  name: 'SpeechArtEdit',
  components: {},
  data() {
    return {
      form: {},
      btnDisabled: false
    }
  },
  computed: {},
  created() {
    this.form = { ...this.$route.query }
  },
  mounted() {},
  methods: {
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },
    async onSubmit() {
      if (
        this._trim(this.form.title) === '' &&
        this._trim(this.form.content) === ''
      ) {
        Toast.fail({
          message: '标题和内容不能为空',
          duration: 3 * 1000
        })
        this.form.title = ''
        this.form.content = ''
        return
      }
      if (this._trim(this.form.title) === '') {
        Toast.fail({
          message: '标题不能为空',
          duration: 3 * 1000
        })
        this.form.title = ''
        return
      }
      if (this._trim(this.form.content) === '') {
        Toast.fail({
          message: '内容不能为空',
          duration: 3 * 1000
        })
        this.form.content = ''
        return
      }
      if (this.$route.params.id || this.$route.query.id) {
        this.speechArtUpdate()
      }
      else {
        this.speechArtAdd()
      }
    },
    async speechArtAdd() {
      this.btnDisabled = true
      const res = await speechArtAdd({
        ...this.form
      })
      if (res.code === 0) {
        Toast.success({
          message: '保存成功',
          duration: 3 * 1000,
          onClose: () => {
            this.btnDisabled = false
            this.$router.push({ name: 'userSpeechArt' })
          }
        })
      }
      else {
        this.btnDisabled = false
      }
    },
    async speechArtUpdate() {
      this.btnDisabled = true
      const res = await speechArtUpdate({
        ...this.form
      })
      if (res.code === 0) {
        Toast.success({
          message: '修改成功',
          duration: 3 * 1000,
          onClose: () => {
            this.btnDisabled = false
            this.$router.push({ name: 'userSpeechArt' })
          }
        })
      }
      else {
        this.btnDisabled = false
      }
    }
  }
}
</script>

<style lang="less" scope>
.speech-art-edit {
  background: #fff;
  height: 100vh;
  padding: 13px 18px;
  .van-form {
    .field-wrapper {
      padding-bottom: 200px;
      .van-cell {
        padding: 10px 0;
        font-size: 16px;
      }
      .van-cell.border-bottom {
        border-bottom: 1px solid #666;
      }
      .van-cell::after {
        display: none;
      }
    }
    .btns {
      width: 100%;
      position: fixed;
      bottom: 50px;
      left: 0;
      display: flex;
      justify-content: center;
      .van-button {
        width: 337px;
        height: 56px;
      }
    }
  }
}
</style>
