export const CUSTOM_USER_INFO = {
  tsmbu: {
    authorizeInfo: {
      status: 'OK',
      token: '5e7d1c9f-5ae9-4baa-966c-3f539a9b4ea5'
    },
    usersCurrentInfo: {
      id: '144115205303168663',
      name: '1473535434722418688'
    }
  },
  gm: {
    authorizeInfo: {
      status: 'OK',
      token: '51c984e2-4093-4f92-bd73-6378535635d9'
    },
    usersCurrentInfo: {
      // id: '144115205301725086',
      // name: '1561601281499635712'
      id: '144115205301725184',
      name: '1579390350078025728'
    }
  },
  audi: {
    authorizeInfo: {
      status: 'OK',
      token: '2610e3f1-1bcb-40f0-857c-d860cf7b18fc'
    },
    usersCurrentInfo: {
      id: '144115205301725656',
      name: '15524147728'
    }
  }
}
