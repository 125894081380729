var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.list && _vm.list.length)?_c('div',{staticClass:"multiSelectList"},[_c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":_vm.userInfo && _vm.userInfo.avatar,"alt":""}})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"top"},[_c('span',[_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.userName))]),_c('span',[_vm._v(_vm._s(_vm.moment(new Date(Number(_vm.userInfo.shareTime))).format( 'YYYY-MM-DD HH:mm:ss' )))])]),_c('div',{staticClass:"Listbox"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"forBox"},[(_vm.proj !== 'audi')?_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toDetailPage(item, index)}}},[_c('img',{style:({
                width: _vm.materialType === '8' ? '32px' : '47px',
                height: _vm.materialType === '8' ? '40px' : '47px'
              }),attrs:{"src":_vm.materialType === '3' || _vm.materialType === '9'
                  ? item.cosPath
                  : _vm.materialType === '2'
                  ? item.simpleImgUrl
                  : _vm.materialType === '7'
                  ? item.videoCoverUrl || _vm.audioBg
                  : _vm.fileBg,"alt":""}}),_c('p',{staticClass:"van-ellipsis"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toDetailPage(item, index)}}},[_c('img',{attrs:{"src":_vm.materialType === '3' || _vm.materialType === '4'
                  ? item.cosPath
                  : _vm.materialType === '5'
                  ? item.videoCoverUrl
                    ? item.videoCoverUrl
                    : _vm.audioBg
                  : item.imgUrl,"alt":""}}),_c('p',{staticClass:"van-ellipsis"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0)])]):_c('EmptyError')],1)}
var staticRenderFns = []

export { render, staticRenderFns }