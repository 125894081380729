<!-- 多选列表 -->
<template>
  <div>
    <div v-if="list && list.length" class="multiSelectList">
      <div class="picture">
        <img :src="userInfo && userInfo.avatar" alt="" />
      </div>
      <div class="list">
        <div class="top">
          <span>{{ userInfo && userInfo.userName }}</span>
          <span>{{
            moment(new Date(Number(userInfo.shareTime))).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          }}</span>
        </div>
        <div class="Listbox">
          <div v-for="(item, index) in list" :key="index" class="forBox">
            <div v-if="proj !== 'audi'" class="box" @click="toDetailPage(item, index)">
              <img
                :src="
                  materialType === '3' || materialType === '9'
                    ? item.cosPath
                    : materialType === '2'
                    ? item.simpleImgUrl
                    : materialType === '7'
                    ? item.videoCoverUrl || audioBg
                    : fileBg
                "
                alt=""
                :style="{
                  width: materialType === '8' ? '32px' : '47px',
                  height: materialType === '8' ? '40px' : '47px'
                }"
              />
              <p class="van-ellipsis">
                {{ item.title }}
              </p>
            </div>
            <div v-else class="box" @click="toDetailPage(item, index)">
              <img
                :src="
                  materialType === '3' || materialType === '4'
                    ? item.cosPath
                    : materialType === '5'
                    ? item.videoCoverUrl
                      ? item.videoCoverUrl
                      : audioBg
                    : item.imgUrl
                "
                alt=""
              />
              <p class="van-ellipsis">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EmptyError v-else />
  </div>
</template>

<script>
import moment from 'moment'
import {
  getChatMulSelByIds,
  getContentMulSelByIds,
  getMatMulSelByIds
} from '@/api/modules/vSearch'
import audioBg from '@/assets/img/vSearch/audioBg.png'
import fileBg from '@/assets/img/vSearch/fileBg.png'
import { ImagePreview } from 'vant'
import EmptyError from '@/components/EmptyError.vue'
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    EmptyError
  },

  data() {
    return {
      materialIdList: [],
      userInfo: {},
      moment,
      list: [],
      audioBg,
      fileBg,
      offsetArr: [],
      proj: ''
    }
  },

  computed: {},
  created() {
    this.proj = process.env.VUE_APP_PROJ
    this.moment = moment
    this.materialIdList = JSON.parse(this.$route.query.materialIdListJson)
    this.offsetArr = JSON.parse(this.$route.query.offsetArrJson)
    this.userInfo = JSON.parse(this.$route.query.userInfoJson)
    this.materialType = this.$route.query.materialType // 1话术，2图文，3图片，4视频，5文件，6海报
    this.getList()
  },
  mounted() {},
  methods: {
    async getList() {
      let res = null
      if (this.materialType === '1') {
        res = await getChatMulSelByIds({
          ids: this.materialIdList
        })
      }
      else if (this.materialType === '2') {
        res = await getContentMulSelByIds({
          contentIdList: this.materialIdList
        })
      }
      else {
        res = await getMatMulSelByIds({
          ids: this.materialIdList
        })
      }
      if (res.code === 0) {
        this.list = res.data
      }
      // const res = await getListApi({
      //   chatStratageId: this.materialIdList
      // })
      // res.data.content = item.cardData.content
    },
    toDetailPage(item, index) {
      let routeName = ''
      if (this.proj !== 'audi') {
        routeName =
          this.materialType === '2'
            ? 'articleDetail'
            : this.materialType === '3'
              ? 'graphicDetail'
              : this.materialType === '7'
                ? 'videoDetail'
                : this.materialType === '8'
                  ? 'fileDetail'
                  : 'posterDetail'
      }
      if (this.proj === 'audi') {
        routeName =
          this.materialType === '2'
            ? 'articleDetail'
            : this.materialType === '3'
              ? 'graphicDetail'
              : this.materialType === '4'
                ? 'posterDetail'
                : 'videoDetail'
      }
      if (routeName === 'graphicDetail' || routeName === 'posterDetail') {
        ImagePreview({ images: [item.cosPath], closeable: true })
      }
      else if (this.proj !== 'audi' && routeName === 'fileDetail') {
        const a = document.createElement('a') // 创建a标签
        a.setAttribute('download', '') // download属性
        a.setAttribute('href', item.cosPath) // href链接
        a.click() // 自执行点击事件
      }
      else {
        this.$router.push({
          name: routeName,
          query: {
            id: item.id || item.contentId,
            notInQw: 1,
            offset: this.offsetArr[index]
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.multiSelectList {
  width: 100%;
  padding: 20px 0 52px 11px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .picture {
    width: 38px;
    height: 38px;
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .list {
    width: calc(100% - 47px);
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-right: 12px;
      span {
        font-family: 'DFPKingGothicGB-Medium';
        font-size: 12px;
        color: #666666;
        letter-spacing: 0;
      }
    }
    .Listbox {
      .forBox {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #ecedf2;
      }
      > div {
        &:first-child {
          padding-top: 0;
        }
      }
    }

    .box {
      display: flex;
      justify-content: flex-start;
      box-sizing: border-box;
      align-items: center;
      background: #ffffff;
      border-radius: 4px;
      padding: 10px 12px;
      width: 270px;
      box-sizing: border-box;
      img {
        width: 47px;
        height: 47px;
        object-fit: cover;
        border-radius: 2px;
      }
      p {
        margin: 0;
        padding-left: 10px;
        width: calc(100% - 47px);
        font-family: 'DFPKingGothicGB-Medium';
        font-size: 16px;
        color: #040404;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
