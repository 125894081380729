<template>
  <div class="user-speech-art">
    <div class="search-wrapper">
      <TopSearch
        theme="day"
        :tabs-data="tabsData"
        :sticky-class="'sub-navbar'"
        :search-padding="searchPadding"
        :top-search-map="topSearchMap"
        maxlength="50"
        @search="search"
        @typeChange="typeChange"
      />
    </div>
    <div class="list-wrapper">
      <div v-for="(item, index) in list" :key="index" class="list-item">
        <div v-if="true || topSearchMap.type === ''" class="speech-art">
          <div
            class="name keywords-mark-f00"
            v-html="keywordsMarkColor(item.title, topSearchMap.keyWord, 'f00')"
          ></div>
          <div
            class="content  keywords-mark-f00"
            v-html="keywordsMarkColor(item.content, topSearchMap.keyWord, 'f00')"
          ></div>
          <div class="btn">
            <div
              class="btn1"
              @click="
                $router.push({
                  name: 'speechArtEdit',
                  query: {
                    id: item.id,
                    title: item.title,
                    content: item.content
                  }
                })
              "
            >
              编辑
            </div>
            <!-- 在线坐席不需要这块，晚点接入企微发送能力 -->
            <div
              v-if="!(item.approveStatus === 4)"
              class="btn2"
              @click="openPopupForm(item)"
            >
              发送
            </div>
          </div>
          <div v-if="item.approveStatus === 4" class="tips">
            部分字段比较敏感，需要调整
          </div>
        </div>
      </div>
      <EmptyError
        v-show="list.length === 0 && emptyErrorType != ''"
        :empty-error-type="emptyErrorType"
      />
    </div>
    <div
      style="width: 50px;height: 50px;background: #020002;border-radius:50%;position:fixed;bottom:100px;right:0;font-size:12px;color:#fff;display:flex;align-items:center;justify-content:center"
      @click="
        $router.push({ name: 'speechArtEdit' })
      "
    >
      新增
    </div>
    <popup-form
      ref="PopupForm"
      title="话术编辑"
      :form-field-map="formFieldMap"
      :form-item-arr="formItemArr"
      :is-show="popupFormShow"
      @sure="formSure"
      @close="popupFormShow = false"
    />
  </div>
</template>
<script>
import TopSearch from '@/components/TopSearch.vue'
import EmptyError from '@/components/EmptyError.vue'
import { personalSpeechArtList, speechArtDetail } from '@/api/modules/vSearch'
import { mapGetters } from 'vuex'
import { sendData } from '@/scripts/iframe'
import wxSDK from '@/scripts/wxsdk'
import { keywordsMarkColor } from '@/scripts/utils'
import PopupForm from '@/components/PopupForm.vue'
import { Toast } from 'vant'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SearchList',
  components: { TopSearch, EmptyError, PopupForm },
  data() {
    return {
      proj: global.APP_CONFIG.auth_type,
      tabsData: [
        {
          name: '全部',
          value: ''
        },
        {
          name: '审核通过',
          value: 6
        },
        {
          name: '审核不通过',
          value: 4
        }
      ],
      list: [],
      topSearchMap: {
        keyWord: '',
        type: ''
      },
      searchPadding: { padding: '0 18px' },
      emptyErrorType: '',
      keywordsMarkColor,
      formFieldMap: {
        sendContent: ''
      },
      formItemArr: [
        {
          field: 'sendContent',
          type: 'textarea',
          label: '发送至对话框'
        }
      ],
      popupFormShow: false
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  async created() {
    const auth_type = global.APP_CONFIG.auth_type
    if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
      await wxSDK.config([], ['sendChatMessage'])
    }
    this.personalSpeechArtList()
    this.keywordsMarkColor = keywordsMarkColor
  },
  mounted() {},
  methods: {
    openPopupForm(item) {
      this.curSendObj = { ...item }
      this.formFieldMap = {
        sendContent: this.curSendObj.content
      }
      this.popupFormShow = true
    },
    // 过滤组件选项确认
    formSure(obj) {
      // this.formFieldMap = { ...obj }
      window.console.log(obj)
      this.curSendObj.content = obj.sendContent
      this.send(this.curSendObj)
    },
    async personalSpeechArtList() {
      const res = await personalSpeechArtList({
        keyWord: this._trim(this.topSearchMap.keyWord),
        approveStatus: this.topSearchMap.type
      })
      if (res.code === 0) {
        this.list = res.data
        if (!res.data || res.data.length === 0) {
          this.emptyErrorType = 'empty'
        }
      }
      else {
        this.emptyErrorType = 'error'
      }
    },
    search(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      this.personalSpeechArtList()
    },
    typeChange(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      this.personalSpeechArtList()
    },
    async send(item) {
      console.log('转发/发送item数据', item)
      if (item.content === '' || this._trim(item.content) === '') {
        Toast.fail({
          message: '发送失败：内容不能为空',
          duration: 3 * 1000
        })
        return
      }
      const materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/speechArtDetail/${item.id}?id=${item.id}&notInQw=1`
      if (self !== top) {
        speechArtDetail
        // const res = await speechArtDetail({
        //   chatStratageId: item.materialId
        // })
        const data = {
          method: 'send',
          id: uuidv4(),
          data: { ...item, materialDetailUrl }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          msgtype: 'text', // 消息类型，必填
          enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: item.content // 文本内容
          },
          image: {
            mediaid: '' // 图片的素材id
          },
          video: {
            mediaid: '' // 视频的素材id
          },
          file: {
            mediaid: '' // 文件的素材id
          },
          news: {
            link: '', // H5消息页面url 必填
            title: '', // H5消息标题
            desc: '', // H5消息摘要
            imgUrl: '' // H5消息封面图片URL
          }
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('text', {
            content: contentJson.text.content
          })
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 2,
            query: '',
            clickId: item.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [1],
            fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
            offset: 0
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scope>
.user-speech-art {
  background: #f5f7fa;
  .search-wrapper {
    background: #fff;
    padding: 13px 0 0 0;
  }
  .list-wrapper {
    padding: 15px 15px 52px 15px;
    .list-item {
      background: #fff;
      padding: 10px;
      margin-bottom: 15px;
      .speech-art {
        .name {
          font-family: DFPKingGothicGB-Medium;
          font-size: 16px;
          color: #050404;
          letter-spacing: 0;
          line-height: 24px;
          margin-bottom: 10px;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content {
          font-family: DFPKingGothicGB-Regular;
          font-size: 14px;
          color: #666666;
          letter-spacing: 0;
          line-height: 21px;
          margin-bottom: 15px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .btn {
          display: flex;
          justify-content: flex-end;
          font-family: DFPKingGothicGB-Regular;
          font-size: 12px;
          color: #000000;
          letter-spacing: 0;
          line-height: 21px;
          div {
            margin-left: 30px;
          }
        }
        .tips {
          margin-top: 10px;
          font-family: DFPKingGothicGB-Regular;
          font-size: 14px;
          color: #f50537;
          letter-spacing: 0;
          line-height: 21px;
        }
      }
    }
  }
}
</style>
