var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-speech-art"},[_c('div',{staticClass:"search-wrapper"},[_c('TopSearch',{attrs:{"theme":"day","tabs-data":_vm.tabsData,"sticky-class":'sub-navbar',"search-padding":_vm.searchPadding,"top-search-map":_vm.topSearchMap,"maxlength":"50"},on:{"search":_vm.search,"typeChange":_vm.typeChange}})],1),_c('div',{staticClass:"list-wrapper"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[(true || _vm.topSearchMap.type === '')?_c('div',{staticClass:"speech-art"},[_c('div',{staticClass:"name keywords-mark-f00",domProps:{"innerHTML":_vm._s(_vm.keywordsMarkColor(item.title, _vm.topSearchMap.keyWord, 'f00'))}}),_c('div',{staticClass:"content  keywords-mark-f00",domProps:{"innerHTML":_vm._s(_vm.keywordsMarkColor(item.content, _vm.topSearchMap.keyWord, 'f00'))}}),_c('div',{staticClass:"btn"},[_c('div',{staticClass:"btn1",on:{"click":function($event){return _vm.$router.push({
                name: 'speechArtEdit',
                query: {
                  id: item.id,
                  title: item.title,
                  content: item.content
                }
              })}}},[_vm._v(" 编辑 ")]),(!(item.approveStatus === 4))?_c('div',{staticClass:"btn2",on:{"click":function($event){return _vm.openPopupForm(item)}}},[_vm._v(" 发送 ")]):_vm._e()]),(item.approveStatus === 4)?_c('div',{staticClass:"tips"},[_vm._v(" 部分字段比较敏感，需要调整 ")]):_vm._e()]):_vm._e()])}),_c('EmptyError',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.length === 0 && _vm.emptyErrorType != ''),expression:"list.length === 0 && emptyErrorType != ''"}],attrs:{"empty-error-type":_vm.emptyErrorType}})],2),_c('div',{staticStyle:{"width":"50px","height":"50px","background":"#020002","border-radius":"50%","position":"fixed","bottom":"100px","right":"0","font-size":"12px","color":"#fff","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({ name: 'speechArtEdit' })}}},[_vm._v(" 新增 ")]),_c('popup-form',{ref:"PopupForm",attrs:{"title":"话术编辑","form-field-map":_vm.formFieldMap,"form-item-arr":_vm.formItemArr,"is-show":_vm.popupFormShow},on:{"sure":_vm.formSure,"close":function($event){_vm.popupFormShow = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }