<template>
  <div class="search-list">
    <div class="search-wrapper">
      <TopSearch
              :tabs-data="tabsData"
              :top-search-map="topSearchMap"
              :top-recommend-search-map-week="topRecommendSearchMapWeek"
              :top-recommend-search-map-total="topRecommendSearchMapTotal"
              :filter-img="filterImg"
              :sticky-class="'sub-navbar'"
              :search-padding="searchPadding"
              :show-associative-data="false"
              theme="day"
              maxlength="50"

              :list="list"
              :page-data-total-count="pageDataTotalCount"
              :search-params="searchParams"
              :empty-error-type="emptyErrorType"
              :loading="loading"
              :has-fixed-btn="!isInIframe"

              @search="search"
              @typeChange="typeChange"
              @filterSelect="openFilter"
              @sure="sureAll"

              @loadMorePage="loadMorePage"
              @updateKeyword="updateKeyword"
              @closeSearch="closeSearch"
      />
    </div>
    <div @click="closeSearch">
      <PopupFilter
              v-if="topSearchMap.type !== '10'"
              ref="popupFilter"
              :filter-field-map="filterFieldMap"
              :filter-item-arr="proj !== 'audi' ? filterItemArr : topSearchMap.type === '3' ||
        topSearchMap.type === '4' ||
        topSearchMap.type === '5' ||
        topSearchMap.type === '6'
          ? [filterItemArr[2], filterItemArr[3], filterItemArr[4]]
          : filterItemArr"
              :is-show="filterShow"
              @sure="filterSure"
              @reset="filterReset"
              @close="filterShow = false"
              @fieldValueChange="fieldValueChange"
      />
      <ActivityPopupFilter
              v-if="topSearchMap.type === '10'"
              ref="popupFilter"
              :filter-field-map="filterFieldMap"
              :filter-item-arr="proj !== 'audi' ? filterItemArr : topSearchMap.type === '3' ||
        topSearchMap.type === '4' ||
        topSearchMap.type === '5' ||
        topSearchMap.type === '6'
          ? [filterItemArr[2], filterItemArr[3], filterItemArr[4]]
          : filterItemArr"
              :is-show="filterShow"
              @sure="filterSure"
              @reset="filterReset"
              @close="filterShow = false"
              @fieldValueChange="fieldValueChange"
      />
    </div>
  </div>
</template>
<script>
import TopSearch from '@/components/TopSearch.vue'
import PopupFilter from '@/components/PopupFilter.vue'
import ActivityPopupFilter from '@/components/ActivityPopupFilter.vue'
import { knowledgeSearch, getAtlas, groupList, groupList2, recommendSearchImageAndVideo, recommendSearchArticle, recommendSearchChart } from '@/api/modules/vSearch'
import { mapGetters } from 'vuex'
import wxSDK from '@/scripts/wxsdk'
import filterImg from '@/assets/img/vSearch/searchFilter@2x.png'
import { TABS_DATA } from '@/views/vSearch/constConfig'

export default {
  name: 'SearchList',
  components: { TopSearch, PopupFilter, ActivityPopupFilter },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      showAssociativeData: false,
      tabsData: TABS_DATA[process.env.VUE_APP_PROJ],
      list: [],
      topSearchMap: {
        keyWord: '',
        type: '0'
      },
      // 推荐 -> 周排行  奥迪
      topRecommendSearchMapWeek: {
        'image': {
          keyWord: '',
          type: '3',
          list: []
        },
        'video': {
          keyWord: '',
          type: '5',
          list: []
        },
        'article': {
          keyWord: '',
          type: '2',
          list: []
        },
        'char': {
          keyWord: '',
          type: '1',
          list: []
        }
      },
      // 推荐 -> 总排行  奥迪
      topRecommendSearchMapTotal: {
        'image': {
          keyWord: '',
          type: '3',
          list: []
        },
        'video': {
          keyWord: '',
          type: '5',
          list: []
        },
        'article': {
          keyWord: '',
          type: '2',
          list: []
        },
        'char': {
          keyWord: '',
          type: '1',
          list: []
        }
      },
      filterFieldMap: {
        publishTimeRange: '',
        firstGrounp: [],
        secondGrounp: [],
        thirdGrounp: [],
        order: ''
      },
      searchPadding: { padding: '0 18px' },
      filterItemArr: process.env.VUE_APP_PROJ !== 'audi' ? [
        {
          field: 'publishTimeRange',
          type: 'timeRange',
          label: '按时间段'
        }
      ] : [
        {
          field: 'publishTime',
          type: 'radio',
          label: '发布时间',
          data: [
            { name: '最新', value: '最新' },
            { name: '最近一月', value: '最近一月' },
            { name: '最近半年', value: '最近半年' }
          ]
        },
        {
          field: 'publishTimeRange',
          type: 'timeRange',
          label: '按时间段'
        },
        {
          field: 'firstGrounp',
          type: 'checkbox',
          label: '一级分组',
          data: [],
          collapseLength: 9
        },
        {
          field: 'secondGrounp',
          type: 'checkbox',
          label: '二级分组',
          data: [
          ],
          collapseLength: 9
        },
        {
          field: 'secondGrounp',
          type: 'checkbox',
          label: '三级分组',
          data: [
          ],
          collapseLength: 9
        }
      ],
      filterShow: false,
      groupTreeData: [],
      searchParams: {
        keyword: '',
        materialType: 1,
        querySource: '',
        publishTime: '',
        beginTime: '',
        endTime: '',
        categoryLv1Ids: [],
        categoryLv2Ids: [],
        pageIndex: 1,
        pageSize: 10
      },
      emptyErrorType: '',
      pageDataTotalCount: 0,
      loading: false,
      filterImg,
      // 记录滚动条位置
      box: '',
      scrollY: ''
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    isInIframe() {
      return self !== top
    }
  },
  watch: {
    async $route(_to, _from) {
      if (_from.name === 'vSearchHome') {
        this.topSearchMap = { ...this.$route.params }
      }
      this.topSearchMap.type = this.topSearchMap.type || '0'
      if (_from.name !== 'vSearchHome') {
        this.filterItemArr[0].data = this.topSearchMap.type === '1' ? [
          { name: '最近一月', value: '最近一月' },
          { name: '最近半年', value: '最近半年' }
        ] : [
          { name: '最新', value: '最新' },
          { name: '最近一月', value: '最近一月' },
          { name: '最近半年', value: '最近半年' }
        ]
      }
      this.groupList()
    }
  },
  async created() {
    console.log('router', this.$router)
    const auth_type = global.APP_CONFIG.auth_type
    if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
      await wxSDK.config([], ['sendChatMessage', 'shareAppMessage'])
    }
    this.topSearchMap = { ...this.$route.params }
    this.topSearchMap.type = this.topSearchMap.type || '0'
    if (this.proj === 'audi') {
      this.filterItemArr[0].data = this.topSearchMap.type === '1' ? [
        { name: '最近一月', value: '最近一月' },
        { name: '最近半年', value: '最近半年' }
      ] : [
        { name: '最新', value: '最新' },
        { name: '最近一月', value: '最近一月' },
        { name: '最近半年', value: '最近半年' }
      ]
    }
    this.groupList()
  },
  mounted() {
    // 监听scroll变化
    this.$nextTick(() => {
      this.box = document.querySelector('#vsearch')
      this.box.addEventListener(
        'scroll',
        function() {
          this.scrollY = document.querySelector('#vsearch').scrollTop
        },
        false
      )
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      console.log(vm, vm.scrollY)
      vm.$nextTick(() => {
        if (document.querySelector('#vsearch')) {
          document.querySelector('#vsearch').scrollTop = vm.scrollY
        }
      })
    })
  },
  // 记录离开时的位置
  beforeRouteLeave(to, from, next) {
    this.scrollY = document.querySelector('#vsearch').scrollTop
    console.log('离开时保存滚动条的位置', this.scrollY)
    next()
  },
  methods: {
    closeSearch() {
      this.showAssociativeData = true
      this.showAssociativeData = false
    },
    loadMorePage() {
      this.searchParams.pageIndex += 1
      this.knowledgeSearch()
    },
    async groupList() {
      if (this.proj !== 'audi') {
        // catId映射值：  1图片 2视频 3文件 5话术 22000图文 20711海报模版
        if (this.topSearchMap.type === '9' || this.topSearchMap.type === '1') {
          const res = await groupList({
            catType: this.topSearchMap.type === '1' ? 2 : 1,
            catId:
                      this.topSearchMap.type === '1'
                        ? 5
                        : this.topSearchMap.type === '2'
                          ? 22000
                          : this.topSearchMap.type === '3'
                            ? 1
                            : this.topSearchMap.type === '7'
                              ? 2
                              : this.topSearchMap.type === '8'
                                ? 3
                                : 20711
          })
          if (res.code === 0) {
            this.groupTreeData = []
            this.filterItemArr = [
              {
                field: 'publishTimeRange',
                type: 'timeRange',
                label: '按时间段'
              }
            ]
            res.data.map((item, index) => {
              this.groupTreeData.push({
                field: `grounp${index}`,
                type: 'checkbox',
                label: item.groupName,
                value: item.groupId,
                data: item.childPath.map(item2 => {
                  return { name: item2.groupName, value: item2.groupId }
                })
              })
              this.filterFieldMap[`grounp${index}`] = []
            })

            this.groupTreeData.map(item => {
              item.data.unshift({ name: '全部', value: 'ALL' })
            })
            this.filterItemArr = this.filterItemArr.concat(this.groupTreeData)
            this.knowledgeSearch('reStart')
          }
        }
        else {
          const res = await groupList2({
            catType: this.topSearchMap.type === '1' ? 2 : 1,
            catId:
                      this.topSearchMap.type === '1'
                        ? 5
                        : this.topSearchMap.type === '2'
                          ? 22000
                          : this.topSearchMap.type === '3'
                            ? 1
                            : this.topSearchMap.type === '7'
                              ? 2
                              : this.topSearchMap.type === '8'
                                ? 3
                                : 20711
          })
          if (res.code === 0) {
            this.groupTreeData = []
            this.filterItemArr = [
              {
                field: 'publishTimeRange',
                type: 'timeRange',
                label: '按时间段'
              }
            ]
            res.data[0].childPath.map((item, index) => {
              this.groupTreeData.push({
                field: `grounp${index}`,
                type: 'checkbox',
                label: item.groupName,
                value: item.groupId,
                data: item.childPath && item.childPath.length > 0 ? item.childPath.map(item2 => {
                  return { name: item2.groupName, value: item2.groupId }
                }) : []
              })
              this.filterFieldMap[`grounp${index}`] = []
            })
            this.groupTreeData.map(item => {
              item.data.unshift({ name: '全部', value: 'ALL' })
            })
            this.filterItemArr = this.filterItemArr.concat(this.groupTreeData)
            this.knowledgeSearch('reStart')
          }
        }
      }
      else {
        const res = await groupList({ catType: this.topSearchMap.type === '1' ? 2 : 1 })
        if (res.code === 0) {
          this.groupTreeData = [...res.data]
          this.filterItemArr[2].data = this.groupTreeData.map(item => {
            return { name: item.groupName, value: item.groupId }
          })
        }
        this.knowledgeSearch('reStart')
      }
    },
    async getList(materialType) {
      const params = {
        keyword: this._trim(this.topSearchMap.keyWord),
        materialType,
        querySource: this.topSearchMap.querySource,
        beginTime: '',
        endTime: '',
        categoryLv1Ids: [],
        categoryLv2Ids: [],
        pageIndex: 1,
        pageSize: materialType === '7' ? 100 : 4,
        order: this.filterFieldMap.order,
        sort: ''
      }
      const res = await knowledgeSearch(params)
      const list = res.data && res.data.dataList.length ? res.data.dataList : []
      return list
    },
    // 加载图片/视频 周排行
    async loadImageAndVideo(range) {
      const res = await recommendSearchImageAndVideo({
        'range': range
      }).catch(() => {})
      // 图片类
      const imgList = res && res.data && res.data.imgList || []
      const mediaList = res && res.data && res.data.mediaList || []
      mediaList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.id,
          title: item.title,
          desc: item.description,
          content: '',
          hasImageUrl: item.videoCoverUrl,
          imageUrl: item.videoCoverUrl + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          format: item.extension,
          materialType: '5',
          materialId: item.id,
          topStr: 'TOP' + (index + 1),
          duration: Number(parseInt(item.duration)) // 单位秒
        }
      })
      imgList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.id,
          title: item.title,
          desc: item.description,
          content: '',
          imageUrl: item.cosPath + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '3',
          materialId: item.id
        }
      })
      if (range === 1) {
        this.topRecommendSearchMapWeek.image.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.image.list = imgList
        this.topRecommendSearchMapWeek.video.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.video.list = mediaList
      }
      else {
        this.topRecommendSearchMapTotal.image.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.image.list = imgList
        this.topRecommendSearchMapTotal.video.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.video.list = mediaList
      }
    },
    // 加载文章周排行
    async loadArticle(range) {
      const res = await recommendSearchArticle({
        'range': range
      }).catch(() => {})
      const articleList = res && res.data && res.data.contentList || []
      articleList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.contentId,
          title: item.title,
          desc: item.description,
          content: '',
          imageUrl: item.imgUrl + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '2',
          materialId: item.contentId
        }
      })
      if (range === 1) {
        this.topRecommendSearchMapWeek.article.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.article.list = articleList
      }
      else {
        this.topRecommendSearchMapTotal.article.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.article.list = articleList
      }
    },
    // 加载话术 周排行
    async loadChart(range) {
      const res = await recommendSearchChart({
        'range': range
      }).catch(() => {})
      const chartList = res && res.data && res.data.chatList || []
      chartList.map((item, index) => {
        item.tags = JSON.parse(item.tags)
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.materialId = item.id
        item.cardData = {
          ...item,
          id: item.id,
          title: item.title,
          desc: item.content,
          content: item.content,
          imageUrl: '',
          orgName: item.groupName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '1',
          materialId: item.id,
          hasLinkMaterial: item.link
        }
      })
      console.log('zbddfsdfsdf', chartList)
      if (range === 1) {
        this.topRecommendSearchMapWeek.char.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.char.list = chartList
      }
      else {
        this.topRecommendSearchMapTotal.char.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.char.list = chartList
      }
    },
    async knowledgeSearch(type) {
      this.emptyErrorType = ''
      this.loading = true
      if (type === 'reStart') {
        this.searchParams.pageIndex = 1
        this.list = []
      }
      if (this.proj !== 'audi') {
        // 取二级分组ids
        let categoryLv2Ids = []
        Object.keys(this.filterFieldMap).forEach(key => {
          if (
            key !== 'publishTimeRange' &&
                    key !== 'firstGrounp' &&
                    key !== 'secondGrounp' &&
                    key !== 'order' &&
                    this.filterFieldMap[key]
          ) {
            const curList = this.filterFieldMap[key].filter(item => item !== 'ALL')
            categoryLv2Ids = categoryLv2Ids.concat(curList)
          }
        })
        // 取一级分组ids
        const categoryLv1Ids = []
        console.log(2, this.filterItemArr)
        this.filterItemArr.map((item, index) => {
          if (item.field !== 'publishTimeRange') {
            if (
              item.data.length > 1 &&
                      !(
                        item.data.length > 1 &&
                              (!this.filterFieldMap[item.field] ||
                                      (this.filterFieldMap[item.field] &&
                                              this.filterFieldMap[item.field].length === 0))
                      )
            ) {
              if (this.filterFieldMap[item.field] && this.filterFieldMap[item.field].length && this.filterFieldMap[item.field][0] === 'ALL') {
                categoryLv1Ids.push(item.value)
              }
            }
          }
        })
        const timeArr = this.filterFieldMap.publishTimeRange ? this.filterFieldMap.publishTimeRange.split(' 至 ') : ''
        this.searchParams = {
          keyword: this._trim(this.topSearchMap.keyWord),
          materialType: this.topSearchMap.type === '0' ? '101' : this.topSearchMap.type,
          querySource: this.topSearchMap.querySource,
          beginTime: timeArr ? new Date(timeArr[0]).getTime() : '',
          endTime: timeArr ? new Date(timeArr[1]).getTime() : '',
          categoryLv1Ids: categoryLv1Ids,
          categoryLv2Ids: categoryLv2Ids,
          pageIndex: this.searchParams.pageIndex,
          pageSize: this.searchParams.pageSize,
          order: this.filterFieldMap.order,
          sort: ''
        }
      }
      if (this.proj === 'audi') {
        this.searchParams = {
          keyword: this._trim(this.topSearchMap.keyWord),
          materialType: this.topSearchMap.type === '0' ? '101' : this.topSearchMap.type,
          querySource: this.topSearchMap.querySource,
          publishTime: this.filterFieldMap.publishTime,
          beginTime: '',
          endTime: '',
          categoryLv1Ids: this.filterFieldMap.firstGrounp,
          categoryLv2Ids: this.filterFieldMap.secondGrounp,
          pageIndex: this.searchParams.pageIndex,
          pageSize: this.searchParams.pageSize,
          order: this.filterFieldMap.order,
          sort: ''
        }
        if (this.filterFieldMap.publishTime === '最新' || this.filterFieldMap.publishTime === '') {
          if (this.filterFieldMap.publishTimeRange) {
            const [beginTime, endTime] = [...this.filterFieldMap.publishTimeRange.split(' 至 ')]
            this.searchParams.beginTime = new Date(beginTime).getTime()
            this.searchParams.endTime = new Date(endTime).getTime()
          }
          else {
            this.searchParams.beginTime = ''
            this.searchParams.endTime = ''
            this.searchParams.order = this.filterFieldMap.publishTime === '最新' ? 'materialUpdateTime' : this.filterFieldMap.order
            this.searchParams.sort = this.filterFieldMap.publishTime === '最新' ? 'DESC' : ''
          }
        }
        else if (this.filterFieldMap.publishTime === '最近一月') {
          this.searchParams.beginTime = new Date().getTime() - 30 * 24 * 60 * 60 * 1000
          this.searchParams.endTime = new Date().getTime()
        }
        else if (this.filterFieldMap.publishTime === '最近半年') {
          this.searchParams.beginTime = new Date().getTime() - 180 * 24 * 60 * 60 * 1000
          this.searchParams.endTime = new Date().getTime()
        }
        if (this.filterFieldMap.properties) {
          this.searchParams.properties = this.filterFieldMap.properties
        }
        if (this.filterFieldMap.unbeginTime) {
          this.searchParams.unbeginTime = this.filterFieldMap.unbeginTime
        }
        if (this.filterFieldMap.progressTime) {
          this.searchParams.progressTime = this.filterFieldMap.progressTime
        }
        if (this.filterFieldMap.overTime) {
          this.searchParams.overTime = this.filterFieldMap.overTime
        }
        if (this.filterFieldMap.start) {
          this.searchParams.beginTime = this.filterFieldMap.start
        }
        if (this.filterFieldMap.end) {
          this.searchParams.endTime = this.filterFieldMap.end
        }
      }
      if (this.searchParams.materialType === '-1') {
        this.loadImageAndVideo(1)
        this.loadImageAndVideo(2)
        this.loadArticle(1)
        this.loadArticle(2)
        this.loadChart(1)
        this.loadChart(2)
        this.$forceUpdate()
      }
      else {
        const res = await knowledgeSearch(this.searchParams).catch(() => {})
        this._track({
          clickType: 4,
          query: JSON.stringify(this.searchParams),
          clickId: '',
          clickTitle: '',
          contentType: [Number(this.topSearchMap.type)],
          fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
          offset: 0
        })
        let curList = res && res.data && res.data.dataList || []
        if (res.code === 0) {
          this.pageDataTotalCount = res.data.page.totalCount
          curList.map((item, index) => {
            if (this.topSearchMap.type === '1') {
              // 话术
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl,
                orgName: item.groupLv1Name,
                time: item.updateTime,
                materialType: item.materialType,
                hasLinkMaterial: item.link
              }
            }
            else if (this.topSearchMap.type === '2') {
              // tsmbu-图文，audi-文章
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl + '?imageMogr2/quality/40',
                orgName: item.orgName,
                time: item.updateTime,
                materialType: item.materialType
              }
            }
            else if (this.topSearchMap.type === '3') {
              // 图片
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl + '?imageMogr2/quality/40',
                orgName: item.orgName,
                time: item.updateTime,
                materialType: item.materialType
              }
            }
            else if (this.topSearchMap.type === '4') {
              // audi-海报
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl + '?imageMogr2/quality/40',
                orgName: item.orgName,
                materialType: item.materialType,
                time: item.updateTime
              }
            }
            else if (this.topSearchMap.type === '5') {
              // audi-多媒体
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                hasImageUrl: item.imageUrl,
                imageUrl: item.imageUrl ? item.imageUrl + '?imageMogr2/quality/40' : '',
                orgName: item.orgName,
                time: item.updateTime,
                format: item.extension,
                materialType: item.materialType,
                duration: Number(parseInt(item.duration)) // 单位秒
              }
            }
            else if (this.topSearchMap.type === '6') {
              // audi-文件
              item.cardData = {
                ...item,
                title: item.title,
                imageUrl: item.imageUrl,
                format: item.extension,
                materialType: item.materialType
              }
            }
            else if (this.topSearchMap.type === '9') {
              // tsmbu-海报
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl,
                orgName: item.orgName,
                time: item.updateTime,
                materialType: item.materialType
              }
            }
            else if (this.topSearchMap.type === '7') {
              // tsmbu-视频
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl,
                orgName: item.orgName,
                time: item.updateTime,
                materialType: item.materialType,
                format: item.extension,
                duration: Number(parseInt(item.duration)) // 单位秒
              }
            }
            else if (this.topSearchMap.type === '8') {
              // tsmbu-文件
              item.cardData = {
                ...item,
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl,
                orgName: item.orgName,
                time: item.updateTime,
                materialType: item.materialType
              }
            }
            else if (this.topSearchMap.type === '10') {
              // tsmbu-活动
              item.cardData = {
                id: item.materialId,
                title: item.title,
                desc: item.description,
                content: item.content,
                imageUrl: item.imageUrl,
                orgName: item.orgName,
                beginTime: item.beginTime,
                endTime: item.endTime,
                properties: item.properties,
                materialType: item.materialType
              }
            }
            if (type === 'reStart') {
              this.list = []
            }
            this.list = this.list.concat(curList)
          })
          if (this.topSearchMap.type === '0') {
            try {
              curList = [{
                atlas: '',
                videoList: [],
                speechList: [],
                graphicList: [],
                imageList: [],
                posterList: [],
                articleList: [],
                activityList: [],
                allList: []
              }]
              // if (type === 'reStart') {
              //   this.list = []
              // }
              this.list = curList
              const videoList = this.proj === 'audi' ? await this.getList('5') : await this.getList('7')
              this.list[0].videoList = videoList
              const speechList = await this.getList('1')
              this.list[0].speechList = speechList.length ? [speechList[0]] : []
              const graphicList = await this.getList('2')
              this.list[0].graphicList = graphicList.length ? [graphicList[0]] : []
              const imageList = await this.getList('3')
              this.list[0].imageList = imageList
              // const activityList = await this.getList('10')
              const posterList = this.proj === 'audi' ? await this.getList('4') : await this.getList('9')
              this.list[0].posterList = posterList
              const articleList = this.proj === 'audi' ? await this.getList('6') : await this.getList('8')
              this.list[0].articleList = articleList.length ? [articleList[0]] : []
              if (this.proj === 'audi') {
                const atlasRes = await getAtlas({ questionContent: this.topSearchMap.keyWord || '' })
                this.list[0].atlas = atlasRes.data
              }
              if (this.topSearchMap.keyWord) {
                this.list[0].allList = this.list[0].allList ? this.list[0].allList : []
                this.list[0].allList = this.list[0].allList.concat(res.data.dataList)
              }
            }
            catch (err) {
              console.log(err)
            }
          }
          if (this.list && this.list.length === 0) {
            this.emptyErrorType = 'empty'
          }
          this.$forceUpdate()
        }
        else {
          this.emptyErrorType = 'error'
        }
      }
      setTimeout(() => {
        this.loading = false
      }, 600)
      document.querySelector('#vsearch').scrollTop = this.scrollY
    },
    openFilter() {
      this.filterShow = true
    },
    // 过滤组件选项filed变化
    fieldValueChange(index, resultVal) {
      if (this.proj === 'audi') {
        if (['3', '4', '5', '6'].includes(this.topSearchMap.type)) {
          if (index === 0) {
            let secondGrounpData = []
            this.groupTreeData.map(item => {
              if (resultVal.indexOf(item.groupId) !== -1) {
                secondGrounpData = secondGrounpData.concat(item.childPath)
              }
            })
            this.filterItemArr[3].data = secondGrounpData.map(item => {
              return { name: item.groupName, value: item.groupId }
            })
            this.$refs.popupFilter.setFieldValue(
              'curFilterFieldMap',
              'secondGrounp',
              []
            )
            this.$refs.popupFilter.setFieldValue(
              'curFilterFieldMap',
              'thirdGrounp',
              []
            )
          }
          else if (index === 1) {
            let thirdGrounpData = []
            this.groupTreeData.map(item => {
              if (item.childPath && item.childPath.length > 0) {
                item.childPath.map(item2 => {
                  if (resultVal.indexOf(item2.groupId) !== -1) {
                    thirdGrounpData = thirdGrounpData.concat(item2.childPath)
                  }
                })
              }
            })
            this.filterItemArr[4].data = thirdGrounpData.map(item => {
              return { name: item.groupName, value: item.groupId }
            })
            this.$refs.popupFilter.setFieldValue(
              'curFilterFieldMap',
              'thirdGrounp',
              []
            )
          }
        }
        else {
          if (index === 0 || index === 1) {
            if (resultVal !== '') {
              this.$refs.popupFilter.setFieldValue(
                'curFilterFieldMap',
                'publishTimeRange',
                ''
              )
            }
          }
          else if (index === 2) {
            let secondGrounpData = []
            this.groupTreeData.map(item => {
              if (resultVal.indexOf(item.groupId) !== -1) {
                secondGrounpData = secondGrounpData.concat(item.childPath)
              }
            })
            this.filterItemArr[3].data = secondGrounpData.map(item => {
              return { name: item.groupName, value: item.groupId }
            })
            this.$refs.popupFilter.setFieldValue(
              'curFilterFieldMap',
              'secondGrounp',
              []
            )
          }
        }
      }
    },
    // 过滤组件选项确认
    filterSure(obj) {
      this.filterFieldMap = { ...obj }
      window.console.log(obj)
      this.knowledgeSearch('reStart')
    },
    sureAll(order) {
      this.filterFieldMap.order = order
      this.knowledgeSearch('reStart')
    },
    // 过滤组件选项重置
    filterReset() {
      this.filterFieldMap = {
        publishTime: '',
        publishTimeRange: '',
        firstGrounp: [],
        secondGrounp: [],
        thirdGrounp: []
      }
      this.knowledgeSearch('reStart')
    },
    search(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      this.knowledgeSearch('reStart')
    },
    updateKeyword(keyWord) {
      this.topSearchMap.keyWord = keyWord
      this.knowledgeSearch('reStart')
    },
    typeChange(topSearchMap) {
      this.list = []
      this.topSearchMap = { ...topSearchMap }
      this.filterFieldMap = {
        publishTime: '',
        publishTimeRange: '',
        firstGrounp: [],
        secondGrounp: [],
        thirdGrounp: []
      }
      this.filterItemArr[3] && (this.filterItemArr[3].data = [])
      this.filterItemArr[4] && (this.filterItemArr[4].data = [])
      // this.filterItemArr
      if (this.proj === 'audi') {
        if (this.topSearchMap.type === '1') {
          this.filterItemArr[0].data = [
            { name: '最近一月', value: '最近一月' },
            { name: '最近半年', value: '最近半年' }
          ]
          if (this.filterFieldMap.publishTime === '最新') {
            this.filterFieldMap.publishTime = ''
          }
        }
        else {
          this.filterItemArr[0].data = [
            { name: '最新', value: '最新' },
            { name: '最近一月', value: '最近一月' },
            { name: '最近半年', value: '最近半年' }
          ]
        }
      }
      this.groupList()
    }
  }
}
</script>

<style lang="less" scope>
  .search-list {
    background: #f5f7fa;
    .search-wrapper {
      background: #fff;
      padding: 13px 0px 0px 0px;
    }
  }
</style>
