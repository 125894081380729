<template>
  <div class="user-center-home">
    <div class="user-info">
      <img :src="userInfo && userInfo.avatar" alt="" />
      <span>{{ userInfo && userInfo.userName }}</span>
    </div>
    <div class="user-menu">
      <div
        v-for="(item, index) in menuList"
        :key="index"
        class="user-menu-item"
        @click="$router.push({ name: item.route })"
      >
        <img src="@/assets/img/vSearch/speechArt@2x.png" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UserCenterHome',
  components: {},
  data() {
    return {
      menuList: [{ name: '个人话术', route: 'userSpeechArt' }]
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scope>
.user-center-home {
  padding: 30px 15px;
  background: #fff;
  height: 100vh;
  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
      border: 1px solid #f5f5f5;
      border-radius: 50%;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #333333;
      letter-spacing: -0.3px;
      line-height: 32px;
    }
  }
  .user-menu {
    .user-menu-item {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      span {
        font-family: DFPKingGothicGB-Regular;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>
