<template>
  <div id="vsearch" class="vsearch" :class="$route.name === 'vSearchHome' ? 'vsearch-bg' : ''">
    <div v-if="showPage">
      <div v-show="needReturnBtn" :class="_userAgentType().platform === 'pc' ? 'to_header_pc' : 'to_header'">
        <to-header />
      </div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>

<script>
import { CUSTOM_USER_INFO } from '@/views/vSearch/customUserInfo'
import './constant'
import { mapGetters } from 'vuex'
import { getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'
import Router from '@/router'
import { getUrlParamsObj } from '@/scripts/utils'
import toHeader from '@/components/Header'
import mta from 'mta-h5'
export default {
  components: { toHeader },
  data() {
    return {
      inited: false,
      needReturnBtn: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      const auth_type = global.APP_CONFIG.auth_type
      const flag =
        auth_type === 'customAuthLogin' ||
        ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') &&
          this.userInfo)
      return flag
    }
  },
  watch: {
    async $route(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
      this.needReturnBtn =
        Router.history.current.name !== 'vSearchHome' &&
        !getUrlParamsObj().notInQw
    }
  },
  async created() {
    await this.init()
    // console.log('ExternalContact:', await wxSDK.getCurExternalContact()) // 会触发企微的loading
  },
  mounted() {
    this.needReturnBtn =
      Router.history.current.name !== 'vSearchHome' &&
      !getUrlParamsObj().notInQw
    mta.register({
      appId: 'A11143237935',
      baseUrl: global.APP_CONFIG.mtaUrl
    })
  },
  methods: {
    async init() {
      const auth_type = global.APP_CONFIG.auth_type
      if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
        await getUser()
        this._track({
          clickType: 1,
          query: '',
          clickId: '',
          clickTitle: '',
          contentType: [],
          fields: [],
          offset: 0
        })
        await wxSDK.config(
          [],
          [
            'getContext',
            'getCurExternalContact',
            'sendChatMessage',
            'shareAppMessage'
          ]
        )
      }
      else if (auth_type === 'customAuthLogin') {
        await this.customAuthLogin()
        this._track({
          clickType: 1,
          query: '',
          clickId: '',
          clickTitle: '',
          contentType: [],
          fields: [],
          offset: 0
        })
      }
      this.inited = true
    },
    async customAuthLogin() {
      const authorizeInfo = CUSTOM_USER_INFO[process.env.VUE_APP_PROJ].authorizeInfo
      const usersCurrentInfo = CUSTOM_USER_INFO[process.env.VUE_APP_PROJ].usersCurrentInfo
      store.commit('userAuth/authorizeInfo', authorizeInfo)
      store.commit('userAuth/usersCurrentInfo', usersCurrentInfo)
      // 此处自定义鉴权和sdk接入
    }
  }
}
</script>

<style lang="less" scoped>
.vsearch {
  overflow-y: scroll;
  height: 100vh;
}
.vsearch-bg {
  background: url('../../assets/img/vSearch/homeBg@2x.png') no-repeat;
  background-size: 100% 100%;
}
.return-btn-wrapper {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 16px;
  .van-button {
    width: 100px;
    height: 32px;
    font-size: 16px;
    border-color: #000;
    border-radius: 0;
  }
}
.to_header {
  width: 100%;
}
.to_header_pc {
  height: 44px;
  width: 100%;
}
</style>
