<template>
  <div class="speech-art-detail">
    <div class="base-detail">
      <div class="title">
        <!-- 差异：奥迪-分组+title；可复制化-title -->
        {{ proj === 'audi' ? '【' + detail.groupName + '】' + detail.title : detail.title }}
      </div>
      <div class="content">{{ detail.content }}</div>
      <div class="btns">
        <van-button
          v-if="!notInQw"
          color="#000"
          @click="
            openPopupForm({
              ...detail,
              offset: $route.query.offset,
              clickType: 3
            })
          "
          >发送</van-button
        >
      </div>
    </div>
    <div class="relation-detail">
      <div class="search-wrapper">
        <div class="title">关联素材</div>
        <TopSearch
          :tabs-data="tabsData"
          :top-search-map="topSearchMap"
          :filter-img="isInIframe ? '' : filterImg"
          :search-padding="searchPadding"
          theme="day"
          :has-input="false"

          ref="cardList"
          :list="list"
          :page-data-total-count="pageDataTotalCount"
          :search-params="searchParams"
          :empty-error-type="emptyErrorType"
          :loading="loading"
          :has-fixed-btn="false"

          maxlength="50"
          @search="search"
          @typeChange="typeChange"
          @filterSelect="filterSelect"
        />
      </div>
      <!-- <CardList
        ref="cardList"
        :list="list"
        :page-data-total-count="pageDataTotalCount"
        :top-search-map="topSearchMap"
        :search-params="searchParams"
        :empty-error-type="emptyErrorType"
        :loading="loading"
        :has-fixed-btn="true"
      /> -->
      <popup-form
        ref="PopupForm"
        title="话术编辑"
        :form-field-map="formFieldMap"
        :form-item-arr="formItemArr"
        :is-show="popupFormShow"
        @sure="formSure"
        @close="popupFormShow = false"
      />
    </div>
  </div>
</template>
<script>
import TopSearch from '@/components/TopSearch.vue'
import { RELATED_TABS_DATA } from '@/views/vSearch/constConfig'
import { speechArtDetail, speechArtRelationList } from '@/api/modules/vSearch'
import { sendData } from '@/scripts/iframe'
import wxSDK from '@/scripts/wxsdk'
import { getUrlParamsObj } from '@/scripts/utils'
import moment from 'moment'
// import CardList from '@/components/CardList.vue'
import PopupForm from '@/components/PopupForm.vue'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import filterImg from '@/assets/img/vSearch/searchAtr@2x.png'
import filterAtr from '@/assets/img/vSearch/searchDetail@2x.png'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'SpeechArtDetail',
  components: { TopSearch, PopupForm },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      list: [],
      pageDataTotalCount: 0,
      topSearchMap: {
        keyWord: '',
        type: '3'
      },
      searchParams: {
        // appId: 0,
        keyword: '',
        materialType: 1,
        publishTime: '',
        beginTime: '',
        endTime: '',
        categoryLv1Ids: [],
        categoryLv2Ids: [],
        pageIndex: 1,
        pageSize: 10
      },
      emptyErrorType: '',
      checkStatus: false,
      loading: false,
      tabsData: RELATED_TABS_DATA[process.env.VUE_APP_PROJ],
      detail: {
        title: '/',
        content: '/',
        groupName: '/'
      },
      searchPadding: { padding: '0 15px' },
      speechArtList: [],
      notInQw: true,
      moment,
      filterImg,
      formFieldMap: {
        sendContent: ''
      },
      formItemArr: [
        {
          field: 'sendContent',
          type: 'textarea',
          label: '发送至对话框'
        }
      ],
      popupFormShow: false
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    isInIframe() {
      return self !== top
    }
  },
  watch: {
    $route(to, from) {
      // 刷新参数放到这里里面去触发就可以刷新相同界面了
      console.log(to, from, 'watch-$route')
      if (to.params.id || to.query.id) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.detail = {
        title: '/',
        content: '/',
        groupName: '/'
      }
      this.list = []
      this.pageDataTotalCount = 0
      this.emptyErrorType = ''
      this.moment = moment
      this.notInQw = getUrlParamsObj().notInQw
      const auth_type = global.APP_CONFIG.auth_type
      if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
        await wxSDK.config([], ['sendChatMessage', 'shareAppMessage'])
      }
      // this.topSearchMap = { ...this.$route.query }
      this.speechArtDetail(this.$route.params.id || this.$route.query.id)
      this.speechArtRelationList(this.$route.params.id || this.$route.query.id)
    },
    detailViewTrack(item) {
      this._track({
        clickType: 5,
        query: JSON.stringify(this.searchParams),
        clickId: this.$route.params.id || this.$route.query.id,
        clickTitle: this.filterLabels(item.title),
        contentType: [1],
        fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [],
        offset: this.$route.query.offset
      })
    },
    // 点击多选触发事件
    filterSelect() {
      this.checkStatus = this.$refs.cardList.isCheckOpen
      this.filterImg = this.checkStatus ? filterImg : filterAtr
      this.$refs.cardList.cardListCheck()
    },
    //  时间处理事件
    timeTypeFomate(date) {
      if (new Date(date).getDate() === new Date().getDate()) {
        return '今日发布'
      }
      else if (
        new Date().getTime() - new Date(date).getTime() <=
        7 * 24 * 60 * 60 * 1000
      ) {
        const dayNum = Math.ceil(
          (new Date().getTime() - new Date(date).getTime()) /
            (24 * 60 * 60 * 1000)
        )
        console.log(dayNum)
        return `${dayNum}天前`
      }
      else {
        return moment(new Date(Number(date))).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 触发话术发送
    openPopupForm(item) {
      this.curSendObj = { ...item }
      this.formFieldMap = {
        sendContent: this.curSendObj.content
      }
      this.popupFormShow = true
    },
    // 过滤组件选项确认
    formSure(obj) {
      // this.formFieldMap = { ...obj }
      window.console.log(obj)
      this.curSendObj.content = obj.sendContent
      this.send(this.curSendObj)
    },
    // 获取话术详情事件
    async speechArtDetail(id) {
      const res = await speechArtDetail({
        chatStratageId: id
      })
      console.log('speechArtDetail')
      console.log(res.data)
      if (res.code === 0) {
        this.detail = res.data
        if (
          this.$route.query.pageFrom &&
          this.$route.query.pageFrom === 'searchList'
        ) {
          this.detailViewTrack(this.detail)
        }
      }
    },
    // 获取素材关联列表事件
    async speechArtRelationList(id) {
      const res = await speechArtRelationList({ ...this.topSearchMap, id })
      if (res.code === 0) {
        res.data.mateList.map(item => {
          if (item.mateType === 1) {
            // 图片
            item.mateList.map(val => {
              val.cardData = {
                id: val.id,
                title: val.title,
                desc: val.description,
                content: val.content,
                imageUrl: val.cosPath,
                orgName: val.orgName,
                time: val.updateTime
              }
            })
          }
          else if (item.mateType === 6) {
            // 海报
            item.mateList.map(val => {
              val.cardData = {
                id: val.id,
                title: val.title,
                desc: val.description,
                content: val.content,
                imageUrl: val.sampleImgUrl,
                orgName: val.orgName,
                time: val.updateTime
              }
            })
          }
          else if (item.mateType === 2) {
            // tsmbu-视频
            if (this.proj !== 'audi') {
              item.mateList.map(val => {
                val.cardData = {
                  id: val.id,
                  title: val.title,
                  desc: val.description,
                  content: val.content,
                  imageUrl: val.videoCoverUrl,
                  orgName: val.orgName,
                  time: val.updateTime,
                  format: val.extension,
                  duration: Number(parseInt(val.exContent)) // 单位秒
                }
              })
            }
            // audi-海报
            if (this.proj === 'audi') {
              item.mateList.map(val => {
                val.cardData = {
                  id: val.id,
                  title: val.title,
                  desc: val.description,
                  content: val.content,
                  imageUrl: val.cosPath,
                  orgName: val.orgName,
                  time: val.updateTime
                }
              })
            }
          }
          else if (item.mateType === 4) {
            // tsmbu-图文, audi-文章
            item.mateList.map(val => {
              val.cardData = {
                id: val.contentId,
                title: val.title,
                desc: val.description,
                content: val.content,
                imageUrl: this.proj === 'audi' ? val.imgUrl : val.simpleImgUrl,
                orgName: val.orgName,
                time: val.updateTime
              }
            })
          }
          else if (item.mateType === 5) {
            // 话术
            item.mateList.map(val => {
              val.cardData = {
                id: val.id,
                title: val.title,
                desc: val.description,
                content: val.content,
                imageUrl: val.cosPath,
                orgName: val.groupName,
                time: val.updateTime,
                hasLinkMaterial: val.link
              }
            })
          }
          else if (item.mateType === 3) {
            // tsmbu-文件
            if (this.proj !== 'audi') {
              item.mateList.map(val => {
                val.cardData = {
                  id: val.id,
                  title: val.title,
                  desc: val.description,
                  content: val.content,
                  imageUrl: val.cosPath,
                  orgName: val.groupName,
                  time: val.updateTime,
                  hasLinkMaterial: val.link
                }
              })
            }
            // audi-多媒体
            if (this.proj === 'audi') {
              item.mateList.map(val => {
                val.cardData = {
                  id: val.id,
                  title: val.title,
                  desc: val.description,
                  content: val.content,
                  imageUrl: val.videoCoverUrl,
                  orgName: val.orgName,
                  time: val.updateTime,
                  format: val.extension,
                  duration: Number(parseInt(val.exContent)) // 单位秒
                }
              })
            }
          }
        })
        this.speechArtList = res.data.mateList
        this.mapList(this.speechArtList)
      }
      else {
        this.emptyErrorType = 'error'
      }
    },
    //  数据处理
    mapList(val) {
      if (this.topSearchMap.type === '1') {
        // 话术
        this.list = val.find(item => item.mateType === 5)?.mateList || []
      }
      else if (this.topSearchMap.type === '2') {
        // 图文
        this.list = val.find(item => item.mateType === 4)?.mateList || []
      }
      else if (this.topSearchMap.type === '3') {
        // 图片
        this.list = val.find(item => item.mateType === 1)?.mateList || []
      }
      else if (this.topSearchMap.type === '4') {
        // audi海报
        this.list = val.find(item => item.mateType === 2)?.mateList || []
      }
      else if (this.topSearchMap.type === '5') {
        // audi多媒体
        this.list = val.find(item => item.mateType === 3)?.mateList || []
      }
      else if (this.topSearchMap.type === '6') {
        // audi文件
        this.list = val.find(item => item.mateType === 6)?.mateList || []
      }
      else if (this.topSearchMap.type === '7') {
        // 视频
        this.list = val.find(item => item.mateType === 2)?.mateList || []
      }
      else if (this.topSearchMap.type === '8') {
        // 文件
        this.list = val.find(item => item.mateType === 3)?.mateList || []
      }
      else if (this.topSearchMap.type === '9') {
        // 海报
        this.list = val.find(item => item.mateType === 6)?.mateList || []
      }
      if (this.list.length === 0) {
        this.emptyErrorType = 'empty'
      }
      this.pageDataTotalCount = this.list.length
    },
    // 点击话术关联素材
    relation(id, index) {
      const url = `${location.href.split('?')[0]}?id=${id}&offset=${index + 1}&t=${new Date().getTime()}`
      location.href = url
    },
    // 发送话术事件
    async send(item) {
      console.log('列表素材详情', item)
      if (item.content === '' || this._trim(item.content) === '') {
        Toast.fail({
          message: '发送失败：内容不能为空',
          duration: 3 * 1000
        })
        return
      }
      const materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/speechArtDetail/${this.$route.params.id}?id=${this.$route.params.id}&notInQw=1`
      if (self !== top) {
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            materialDetailUrl,
            trackData: {
              catId: 5,
              clickType: item.clickType, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发)，4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: this.$route.params.id || this.$route.query.id, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [1], // 1话术，2图文，3图片，4视频，5文件，6海报(audi 1话术，2图文，3图片，4海报，5多媒体)
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [], // 当前搜索过滤字段
              offset: item.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          msgtype: 'text', // 消息类型，必填
          enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: item.content // 文本内容
          },
          image: {
            mediaid: '' // 图片的素材id
          },
          video: {
            mediaid: '' // 视频的素材id
          },
          file: {
            mediaid: '' // 文件的素材id
          },
          news: {
            link: '', // H5消息页面url 必填
            title: '', // H5消息标题
            desc: '', // H5消息摘要
            imgUrl: '' // H5消息封面图片URL
          }
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('text', {
            content: contentJson.text.content
          })
          console.log('企微中发送数据')
          this._track({
            clickType: item.clickType,
            query: JSON.stringify(this.searchParams),
            clickId: this.$route.params.id || this.$route.query.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [1],
            fields: [],
            offset: item.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
    },
    search(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
    },
    typeChange(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      this.mapList(this.speechArtList)
      this.checkStatus = true
      this.filterImg =
        topSearchMap.type === '1' || topSearchMap.type === '9'
          ? 'atrDetail'
          : this.checkStatus
            ? filterImg
            : filterAtr
    }
  }
}
</script>

<style lang="less" scoped>
.speech-art-detail {
  .base-detail {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    .title {
      font-family: DFPKingGothicGB-Medium;
      font-size: 16px;
      color: #050404;
      letter-spacing: 0;
      line-height: 24px;
    }
    .content {
      font-family: DFPKingGothicGB-Regular;
      font-size: 14px;
      color: #333333;
      text-align: left;
      line-height: 26px;
      margin: 15px 0 10px 0;
    }
    .btns {
      display: flex;
      justify-content: center;
      .van-button {
        width: 90px;
        height: 32px;
        border: 1px solid #000000;
        margin-left: 10px;
        font-family: DFPKingGothicGB-Regular;
        font-size: 16px;
      }
    }
  }
  .relation-detail {
    .search-wrapper {
      background: #fff;
      padding: 20px 0px 0 0px;
      .title {
        font-family: DFPKingGothicGB-Medium;
        padding: 0 15px;
        font-size: 16px;
        color: #050404;
        text-align: left;
        line-height: 24px;
      }
    }
    .list-wrapper {
      padding: 15px 15px 52px 15px;
      .list-item {
        background: #fff;
        padding: 10px;
        margin-bottom: 15px;
        .speech-art {
          .name {
            font-family: DFPKingGothicGB-Medium;
            font-size: 16px;
            color: #050404;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content {
            font-family: DFPKingGothicGB-Regular;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 15px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
          .btn {
            display: flex;
            justify-content: flex-end;
            font-family: DFPKingGothicGB-Regular;
            font-size: 12px;
            color: #000000;
            letter-spacing: 0;
            line-height: 21px;
            div {
              margin-left: 30px;
            }
          }
        }
        .article {
          .name {
            font-family: DFPKingGothicGB-Medium;
            font-size: 16px;
            color: #050404;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              width: 195px;
              .top {
                font-family: DFPKingGothicGB-Regular;
                font-size: 14px;
                color: #666666;
                letter-spacing: 0;
                line-height: 21px;
                margin-bottom: 11px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
              .bottom {
                font-family: DFPKingGothicGB-Regular;
                font-size: 10px;
                color: #999999;
                line-height: 16px;
              }
            }
            .right {
              width: 120px;
              height: 90px;
              background-size: 100%;
              // img {
              //   width: 120px;
              //   height: 90px;
              // }
              display: flex;
              align-items: center;
              overflow: hidden;
              img {
                width: 100%;
              }
            }
          }
        }
        // .name {
        //   font-family: DFPKingGothicGB-Medium;
        //   font-size: 16px;
        //   color: #050404;
        //   letter-spacing: 0;
        //   line-height: 24px;
        //   margin-bottom: 10px;
        // }
        // .content {
        //   font-family: DFPKingGothicGB-Regular;
        //   font-size: 14px;
        //   color: #666666;
        //   letter-spacing: 0;
        //   line-height: 21px;
        //   margin-bottom: 15px;
        // }
        // .btn {
        //   display: flex;
        //   justify-content: flex-end;
        //   font-family: DFPKingGothicGB-Regular;
        //   font-size: 12px;
        //   color: #000000;
        //   letter-spacing: 0;
        //   line-height: 21px;
        //   div {
        //     margin-left: 30px;
        //   }
        // }
      }
    }
  }
}
</style>
