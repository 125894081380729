<template>
  <div class="popup-filter">
    <van-action-sheet
      v-model="curIsShow"
      :round="false"
      title="筛选"
      @close="close"
    >
      <div class="content">
        <div class="content-title">按活动状态查询</div>
        <div class="content-status">
          <div
            v-for="(item, i) in ['未开始', '进行中', '已结束']"
            :key="i"
            :class="curStatus === item ? 'content-status-item content-status-item-active' : 'content-status-item'"
            @click="curStatus = curStatus === item ? '' : item"
          >
            {{ item }}
          </div>
        </div>
        <div class="content-title">按活动形式查询</div>
        <div class="content-status">
          <div
            v-for="(item, i) in ['线上活动', '线下活动', '线上+线下']"
            :key="i"
            :class="properties === item ? 'content-status-item content-status-item-active' : 'content-status-item'"
            @click="properties = properties === item ? '' : item"
          >
            {{ item }}
          </div>
        </div>
        <div class="content-title">按活动时间查询</div>
          <div class="content-time" @click="show = true">
            <span v-if="start">{{ getTime(start) }}</span>
            <span v-else>开始时间</span>
            <span>-</span>
            <span v-if="end">{{ getTime(end) }}</span>
            <span v-else>结束时间</span>
          </div>
          <van-calendar
            v-model="show"
            type="range"
            :show-confirm="false"
            allow-same-day
            @confirm="date => onConfirm(date)"
            @opened="calendarOpened"
          />
      </div>
      <div class="btns">
        <van-button type="default" @click="reset">重置</van-button>
        <van-button color="#000" @click="sure">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PopupFilter',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    filterFieldMap: {
      type: Object,
      default: () => {}
    },
    filterItemArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      curIsShow: false,
      curFilterFieldMap: {},
      curStatus: '',
      properties: '',
      show: false,
      curDate: '',
      start: '',
      end: ''
    }
  },
  watch: {
    isShow(val) {
      this.curIsShow = this.isShow
    },
    filterFieldMap: {
      handler(val) {
        console.log('val')
        console.log(val)
        this.curFilterFieldMap = { ...this.filterFieldMap }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.curIsShow = this.isShow
    this.curFilterFieldMap = { ...this.filterFieldMap }
  },
  methods: {
    onConfirm(date) {
      if (date.length === 0) {
        this.curDate = ''
      }
      else {
        const [start, end] = date
        this.start = new Date(start).getTime()
        this.end = new Date(new Date(end).getTime() + 3600 * 1000 * 24 - 1).getTime()
        const changeEnd = new Date(new Date(end).getTime() + 3600 * 1000 * 24 - 1)
        this.show = false
        this.curDate = `${moment(start).format('YYYY-MM-DD')} 至 ${moment(changeEnd).format('YYYY-MM-DD')}`
      }
    },
    getTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    reset() {
      this.curStatus = ''
      this.properties = ''
      this.start = ''
      this.end = ''
    },
    close() {
      this.$emit('close')
    },
    sure() {
      if (this.curStatus === '未开始') {
        this.curFilterFieldMap.unbeginTime = new Date().getTime()
        this.curFilterFieldMap.progressTime = ''
        this.curFilterFieldMap.overTime = ''
      }
      else if (this.curStatus === '进行中') {
        this.curFilterFieldMap.unbeginTime = ''
        this.curFilterFieldMap.progressTime = new Date().getTime()
        this.curFilterFieldMap.overTime = ''
      }
      else if (this.curStatus === '已结束') {
        this.curFilterFieldMap.unbeginTime = ''
        this.curFilterFieldMap.progressTime = ''
        this.curFilterFieldMap.overTime = new Date().getTime()
      }
      else {
        this.curFilterFieldMap.unbeginTime = ''
        this.curFilterFieldMap.progressTime = ''
        this.curFilterFieldMap.overTime = ''
      }
      if (this.properties === '线上活动') {
        this.curFilterFieldMap.properties = 1
      }
      else if (this.properties === '线下活动') {
        this.curFilterFieldMap.properties = 2
      }
      else if (this.properties === '线上+线下') {
        this.curFilterFieldMap.properties = 3
      }
      else {
        this.curFilterFieldMap.properties = ''
      }
      this.curFilterFieldMap.start = this.start
      this.curFilterFieldMap.end = this.end
      this.$emit('sure', { ...this.curFilterFieldMap })
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.popup-filter {
  /deep/.van-action-sheet__header {
    padding: 0 15px;
    text-align: left;
    .van-icon {
      color: #050404;
    }
  }
  /deep/.van-action-sheet__content {
    padding: 15px;
    .content {
      padding-bottom: 100px;
      .content-title {
        font-family: DFPKingGothicGB-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #050404;
        text-align: left;
        line-height: 24px;
      }
      .content-status {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        .content-status-item {
          padding: 8px 26px;
          background: #F2F2F4;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 14px;
          color: #050404;
          line-height: 20px;
          margin-right: 20px;
          margin-bottom: 20px;
        }
        .content-status-item-active {
          background: #000000;
          color: #FFFFFF;
        }
      }
      .content-time {
        margin-top: 15px;
        border: 1px solid #666666;
        padding: 8px 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #050404;
        line-height: 20px;
      }
    }
  }
  /deep/.btns {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    right: 15px;
    width: 100%;
    background: #fff;
    .van-button {
      width: 90px;
      height: 32px;
      border: 1px solid #000000;
      margin-left: 10px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 16px;
    }
  }
  /deep/.time-input-wrapper {
    border: 1px solid #999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 30px;
    position: relative;
    .van-cell {
      .van-cell__value {
        flex: 2;
      }
    }
    .clear-btn {
      transition: 0.2s;
      position: absolute;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      font-size: 10px;
      color: #fff;
      background: #aaa;
    }
  }
}
</style>
